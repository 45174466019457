<template>
  <div class="carousel">
    <vue-horizontal
      ref="horizontal"
      class="horizontal"
      :button-between="false"
      @scroll-debounce="onScrollDebounce"
    >
      <div class="item" v-for="(slide, index) in slides" :key="index">
        <img width="400" :src="slide.image" />
        <div class="content">
          <h2>{{ translate(slide.title) }}</h2>
          <!-- <a :href="translate(slide.button_link)" target="_blank"><h4>{{ translate(slide.button_text) }}</h4></a> -->
          <p>{{ translate(slide.description) }}</p>
          <button
            v-if="translate(slide.button_link)"
            @click="$router.push(translate(slide.button_link))"
          >
            {{ translate(slide.button_text) }}
          </button>
        </div>
      </div>
    </vue-horizontal>

    <div class="dots ml-5">
      <div
        class="dot"
        :class="{ current: i === index }"
        v-for="(slide, i) in slides"
        :key="i"
        @click="onIndexClick(i)"
      >
        <div></div>
      </div>
    </div>
  </div>

  <!-- <carousel
    class="carousel"
    :perPage="1"
    :navigationEnabled="true"
    paginationPosition="bottom-overlay"
    :paginationColor="paginationColor"
  >
    <slide class="slide" v-for="(slide, index) in slides" :key="index">
      <img width="400" :src="slide.image" />
      <div class="slide-info">
        <h1>{{ translate(slide.title) }}</h1>
        <p>{{ translate(slide.description) }}</p>
        <b-button variant="secondary" class="carousel-button" @click="toLink()">
          {{ translate(slide.button_text) }}
        </b-button>
      </div>
    </slide>
  </carousel> -->
</template>
<script>
import VueHorizontal from "vue-horizontal";
import { translateSetting } from "@/utils/translated-setting.util";

export default {
  props: ["carousel"],
  components: {
    VueHorizontal,
  },
  data() {
    return {
      slides: this.carousel.slides,
      hasPrev: false,
      hasNext: false,
      interval: null,
      scrollWidth: 0,
      left: 0,
      progress: 0,
      index: 0,
    };
  },
  mounted() {
    // Custom observe visibility is below
    // Much easier way: https://www.npmjs.com/package/vue-observe-visibility
    observeVisibility(this.$refs.horizontal.$el, (visible) => {
      if (visible) {
        this.interval = setInterval(this.play, 10000);
      } else {
        clearInterval(this.interval);
      }
    });
  },
  destroyed() {
    clearInterval(this.interval);
  },
  computed: {
    backgroundImage() {
      console.log("banner " + this.carousel);
      return this.carousel.image;
    },
    locale() {
      return this.$root.$i18n.locale;
    },
    styling() {
      return this.$store.state.Styling.styling;
    },
    paginationColor() {
      return this.styling.secondary;
    },
  },
  methods: {
    translate(value) {
      return translateSetting(value, this.locale);
    },
    onScrollDebounce({ hasNext, hasPrev, scrollWidth, width, left }) {
      this.hasPrev = hasPrev;
      this.hasNext = hasNext;
      this.scrollWidth = scrollWidth;
      this.left = left;
      this.progress = left / scrollWidth;
      this.index = Math.round(left / width);
    },
    onIndexClick(i) {
      this.$refs.horizontal.scrollToIndex(i);
    },
    play() {
      if (!this.hasNext && this.hasPrev) {
        this.$refs.horizontal.scrollToIndex(0);
        return;
      }

      if (this.hasNext) {
        this.$refs.horizontal.next();
      }
    },
  },
};

function observeVisibility(element, callback) {
  const observer = new IntersectionObserver(
    (records) => {
      callback(records.find((record) => record.isIntersecting));
    },
    { rootMargin: "10% 0% 10% 0%", threshold: 0.5 }
  );
  observer.observe(element);
}
</script>
<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.carousel {
  margin-bottom: 2rem;
  .slide {
    display: flex;
    background-color: #f7fafc;
    .slide-info {
      height: 100%;
      padding: 15px;
      .carousel-button {
        position: absolute;
        bottom: 45px;
      }
    }
  }
}
</style>
<style scoped>
.item {
  width: 100%;
  background: #f7fafc;
  overflow: hidden;
  display: flex;
}

img {
  width: 50%;
  object-fit: cover;
}

.content {
  padding: 32px;
}

.content h2 {
  margin-bottom: 8px;
}

.content p {
  margin: 8px 0;
}

button {
  margin-top: 24px;

  padding: 6px 24px;
  font-size: 18px;
  border-radius: 3px;
  background: white;
  font-weight: 600;
  border: 1px solid #333333;
}

.carousel {
  position: relative;
}

.dots {
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.dot {
  padding: 4px;
  cursor: pointer;
}

.dot > div {
  border-radius: 10px;
  width: 10px;
  height: 10px;
  background: #33333350;
}

.dot:hover > div {
  background: white;
  border: 1px solid black;
}

.dot.current > div {
  border: 3px solid black;
  background: white;
}

.horizontal >>> .v-hl-btn svg {
  box-shadow: none;
  margin: -20px;
}
</style>

<!-- Parent CSS (Container) -->
<style scoped>
main,
header {
  padding: 0 24px;
}

@media (min-width: 768px) {
  main,
  header {
    padding: 0 48px;
  }
}

@media (max-width: 768px) {
  .horizontal >>> .v-hl-btn svg {
    display: none;
  }
}

article {
  padding: 24px 0;
}
</style>
