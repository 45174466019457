<template>
  <b-col cols="12">
    <b-card v-if="isPlenary" class="mb-3">
      Click here to join {{ sessionTitle }} @ {{ sessionStart }} CET
      <b-link :href="sessionLink" class="btn btn-primary float-right">{{
        $t("join")
      }}</b-link>
    </b-card>
    <b-card v-if="isPlenary" class="mb-3">
      Click here to join {{ workshopTitle }} @ {{ workshopStart }} CET
      <b-link :href="workshopLink" class="btn btn-primary float-right">{{
        $t("join")
      }}</b-link>
    </b-card>
    <b-card v-if="!isPlenary">
      Click here to go back to the main livestream
      <b-link :href="plenaryLink" class="btn btn-primary float-right">{{
        $t("join")
      }}</b-link>
    </b-card>
  </b-col>
</template>
<script>
export default {
  components: {},
  computed: {
    nextEventLink() {
      return "";
    },
    currentUser() {
      return this.$store.getters["Auth/currentUser"];
    },
    session() {
      return this.currentUser?.event_sessions?.find(
        (es) => es.room_name === "breakout"
      );
    },
    workshop() {
      return this.currentUser?.event_sessions?.find(
        (es) => es.room_name === "workshop"
      );
    },
    isPlenary() {
      return (
        `${window.location.pathname}${window.location.search}` ===
        this.plenaryLink
      );
    },
    plenary() {
      return this.currentUser?.event_sessions?.find(
        (es) => es.room_name === "Plenary"
      );
    },
    plenaryLink() {
      return this.plenary?.room_url;
    },
    sessionTitle() {
      return this.session?.title;
    },
    sessionStart() {
      return this.session?.start.substring(0, this.session.start.length - 3);
    },
    sessionLink() {
      return this.session?.room_url;
    },
    workshopTitle() {
      return this.workshop?.title;
    },
    workshopStart() {
      return this.workshop?.start.substring(0, this.session.start.length - 3);
    },
    workshopLink() {
      return this.workshop?.room_url;
    },
  },
};
</script>